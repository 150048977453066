/**/

@import url("bootstrap.min.css");
@import url("fontface.css");
@import url("head.css");
html {
	position:relative;
	min-height:100%
}
body {
	font-family:"open_sansregular", sans-serif;
	margin-bottom:240px
}
body#login, div#login {
	background-color: #d1d1ff;
	color: #380e7d;
}
div#login label {
    margin-bottom: 1rem;
}
div#login h2 {
    color: #380e7d;
    margin: 10px 0 0;
    font-size: 3rem;
    line-height: 3.5rem;
}
.footer {
	position:absolute;
	bottom:0;
	width:100%;
	height:245px;
	background-color:#e9e9e9;
	background:#006;
	background:-moz-linear-gradient(top, #006 0, #6c6cc7 100%);
	background:-webkit-gradient(left top, left bottom, color-stop(0, #006), color-stop(100%, #6c6cc7));
	background:-webkit-linear-gradient(top, #006 0, #6c6cc7 100%);
	background:-o-linear-gradient(top, #006 0, #6c6cc7 100%);
	background:-ms-linear-gradient(top, #006 0, #6c6cc7 100%);
	background:linear-gradient(to bottom, #006 0, #6c6cc7 100%)
}
#footerWrapper {
	padding:20px 0 0
}
#footer ul.list-unstyled a, #footer p, #footer a {
	font-family:'open_sanssemibold';
	font-size:9pt;
	line-height:12.5pt;
	color:#fff
}
#footerLogos img {
	text-align:center;
	margin:0 2px
}


@media only screen and (max-width: 767px ) {
	
	#footerLogos {
	    margin: 0 auto;
	    float: none;
	    text-align: center;
	}

	#footerLogos img {
	    text-align: center;
	    margin: 0 auto;
	    width: 35%;
	    height: auto;
	}
	#footerLogos .img-responsive {
	    margin: 0 auto 20px;
	}
	#footer ul.list-unstyled a, #footer p, #footer a {
	    font-size: 9pt;
	    line-height: 12.5pt;
	    color: #fff;
	    text-align: center;
	}
	#footerAddress {
		margin-top:10px;
		}
	#footerWrapper img {
	    margin: 0 auto;
	}

}




.btn {
	border-radius:0;
}

#pageContent .btn-primary {
	background:#fdeffa;
	color:#be1698;
	border-color:#be1698;
}
#pageContent .btn-primary:hover {
	background:#be1698;
	color:#fff;
	border-color:#be1698;
}
.btn-info {
	background-color:#be1698;
	border-color: darken(#be1698,10);
	min-height:22px;
	padding: 4px 8px 6px;
}
.btn-info:hover {
	background-color:darken(#be1698,10);
	border-color: darken(#be1698,10);
}
.btn-danger {
	/*background:#fab1b3;*/
}
.btn-warning {
	background:#eddac0;
	background:-moz-linear-gradient(top, #eddac0 0, #eea236 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0, #eddac0), color-stop(100%, #eea236));
	background:-webkit-linear-gradient(top, #eddac0 0, #eea236 100%);
	background:-o-linear-gradient(top, #eddac0 0, #eea236 100%);
	background:-ms-linear-gradient(top, #eddac0 0, #eea236 100%);
	background:linear-gradient(to bottom, #eddac0 0, #eea236 100%)
}
.btn-success {
	background:#cb60b3;
	background:-moz-linear-gradient(top, #cb60b3 0, #c146a1 47%, #780056 99%, #780056 100%);
	background:-webkit-gradient(left top, left bottom, color-stop(0, #cb60b3), color-stop(47%, #c146a1), color-stop(99%, #780056), color-stop(100%, #780056));
	background:-webkit-linear-gradient(top, #cb60b3 0, #c146a1 47%, #780056 99%, #780056 100%);
	background:-o-linear-gradient(top, #cb60b3 0, #c146a1 47%, #780056 99%, #780056 100%);
	background:-ms-linear-gradient(top, #cb60b3 0, #c146a1 47%, #780056 99%, #780056 100%);
	background:linear-gradient(to bottom, #cb60b3 0, #c146a1 47%, #780056 99%, #780056 100%)
}
btn-warning {
	background:#eabf83;
	background:-moz-linear-gradient(top, #eabf83 0, #ec971f 44%, #ec971f 70%, #e07700 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0, #eabf83), color-stop(44%, #ec971f), color-stop(70%, #ec971f), color-stop(100%, #e07700));
	background:-webkit-linear-gradient(top, #eabf83 0, #ec971f 44%, #ec971f 70%, #e07700 100%);
	background:-o-linear-gradient(top, #eabf83 0, #ec971f 44%, #ec971f 70%, #e07700 100%);
	background:-ms-linear-gradient(top, #eabf83 0, #ec971f 44%, #ec971f 70%, #e07700 100%);
	background:linear-gradient(to bottom, #eabf83 0, #ec971f 44%, #ec971f 70%, #e07700 100%)
}
#productCategory .btn {
	display:inline-block;
	border-radius:0
}
.btn-xs, .btn-group-xs>.btn {
	padding: 5px;
	min-height:22px;
	font-size:9pt;
	line-height:1.5;
	border-radius:0
}
.btnMiniCart {
    min-height: 22px;
    padding: 5px;
    margin: 4px 0 0 4px;
}
.btn-colours {
	color:#fff;
	text-transform:uppercase;
	padding: 0 5px;
    min-height: 29px;
    line-height: 30px;
    border: 1px solid;
	background:#d63f31;
	background:-moz-linear-gradient(left, #d63f31 0, #d86e03 16%, #ced814 32%, #4bcc17 48%, #15c3be 63%, #223bd8 81%, #c21cc7 100%);
	background:-webkit-gradient(linear, left top, right top, color-stop(0, #d63f31), color-stop(16%, #d86e03), color-stop(32%, #ced814), color-stop(48%, #4bcc17), color-stop(63%, #15c3be), color-stop(81%, #223bd8), color-stop(100%, #c21cc7));
	background:-webkit-linear-gradient(left, #d63f31 0, #d86e03 16%, #ced814 32%, #4bcc17 48%, #15c3be 63%, #223bd8 81%, #c21cc7 100%);
	background:-o-linear-gradient(left, #d63f31 0, #d86e03 16%, #ced814 32%, #4bcc17 48%, #15c3be 63%, #223bd8 81%, #c21cc7 100%);
	background:-ms-linear-gradient(left, #d63f31 0, #d86e03 16%, #ced814 32%, #4bcc17 48%, #15c3be 63%, #223bd8 81%, #c21cc7 100%);
	background:linear-gradient(to right, #d63f31 0, #d86e03 16%, #ced814 32%, #4bcc17 48%, #15c3be 63%, #223bd8 81%, #c21cc7 100%)
}
.btn-colours:hover {
	background-color: #be1698;
	color:#fff;
}
.btn-colours i {
    /* background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 6px 5px 8px;
    position: relative;
    top: 4px;*/
    font-style: normal;
}

.btn-colours span:hover {
    background-color: transparent;
}
.modal-header {
	min-height:16.42857143px;
	padding:0 15px;
	border-botpm:1px solid #5bc0de
}
.modal-footer {
	padding:3px 15px 10px;
	text-align:right;
	border-top:1px solid #5bc0de
}
#pageContent {
	padding:1px 0 20px
}
.noPad {
	padding:0
}
.noPadRight {
	padding:0 0 0 15px
}
.noPadLeft {
	padding:0 35px 0 0
}
.noPadLeft2 {
	padding:0 5px 0 0
}
*, *::after, *::before {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box
}
@media only screen and (max-width:1169px) {
body.nav-on-left.overflow-hidden {
	overflow:hidden
}
}
#cd-search input {
	font-family:sans-serif;
	font-size:3.5rem
}
input[type="search"]::-ms-clear {
display:none
}
#cd-search input[type="search"]::-webkit-search-decoration, #cd-search input[type="search"]::-webkit-search-cancel-button, #cd-search input[type="search"]::-webkit-search-results-button, #cd-search input[type="search"]::-webkit-search-results-decoration {
display:none
}
#mainNav {
	height:50px;
	padding:0
}
.cd-main-content {
	background:none repeat scroll 0 0 #fff;
	min-height:100vh;
	z-index:2;
	margin-top:148px
}
.cd-main-header {
	top:140px;
	background:#6c6cc7;
	background:-moz-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-webkit-gradient(left top, left bottom, color-stop(0, #6c6cc7), color-stop(100%, #006));
	background:-webkit-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-o-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-ms-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:linear-gradient(to bottom, #6c6cc7 0, #006 100%);
	-webkit-box-shadow:0 2px 5px 0 rgba(0,0,0,0.75);
	-moz-box-shadow:0 2px 5px 0 rgba(0,0,0,0.75);
	box-shadow:0 2px 5px 0 rgba(0,0,0,0.75)
}
.cd-main-content, .cd-main-header {
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	will-change:transform
}
.cd-main-content, .cd-main-header {
	position:relative;
	-webkit-transition:-webkit-transform .3s;
	-moz-transition:-moz-transform .3s;
	transition:transform .3s
}
@media only screen and (max-width:1169px) {
.cd-main-content.nav-is-visible, .cd-main-header.nav-is-visible {
	-webkit-transform:translateX(-260px);
	-moz-transform:translateX(-260px);
	-ms-transform:translateX(-260px);
	-o-transform:translateX(-260px);
	transform:translateX(-260px)
}
.nav-on-left .cd-main-content.nav-is-visible, .nav-on-left .cd-main-header.nav-is-visible {
	-webkit-transform:translateX(260px);
	-moz-transform:translateX(260px);
	-ms-transform:translateX(260px);
	-o-transform:translateX(260px);
	transform:translateX(260px)
}
#mainNav nav a, #mainNav nav a:focus {
	color:#fff
}
nav a:hover {
	color:#dedede
}
nav h3 {
	font-size:10pt
}
}
.nav-is-fixed .cd-main-header {
	position:fixed;
	top:0;
	left:0;
	width:100%
}
@media only screen and (min-width:1170px) {
.cd-main-header {
	height:44px
}
.cd-main-header:after {
	content:"";
	display:table;
	clear:both
}
}
.cd-logo {
	position:absolute;
	top:12px;
	left:5%
}
.cd-logo img {
	display:block
}
@media only screen and (max-width:1169px) {
.nav-on-left .cd-logo {
	left:auto;
	right:5%
}
}
@media only screen and (min-width:1170px) {
.cd-logo {
	top:26px;
	left:4em
}
}
.cd-header-buttons {
	position:absolute;
	display:inline-block;
	top:3px;
	right:5%
}
.cd-header-buttons li {
	display:inline-block
}
@media only screen and (max-width:1169px) {
.nav-on-left .cd-header-buttons {
	right:auto;
	left:5%
}
.nav-on-left .cd-header-buttons li {
	float:right
}
}
@media only screen and (min-width:1170px) {
.cd-header-buttons {
	top:1px;
	right:4em
}
}
.cd-search-trigger, .cd-nav-trigger {
	position:relative;
	display:block;
	width:44px;
	height:44px;
	overflow:hidden;
	white-space:nowrap;
	color:transparent;
	z-index:3
}
.cd-search-trigger::before, .cd-search-trigger::after {
	content:'';
	position:absolute;
	-webkit-transition:opacity .3s;
	-moz-transition:opacity .3s;
	transition:opacity .3s;
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden
}
.cd-search-trigger::before {
	top:11px;
	left:11px;
	width:18px;
	height:18px;
	border-radius:50%;
	border:3px solid #fff
}
.cd-search-trigger::after {
	height:3px;
	width:8px;
	background:#fff;
	bottom:14px;
	right:11px;
	-webkit-transform:rotate(45deg);
	-moz-transform:rotate(45deg);
	-ms-transform:rotate(45deg);
	-o-transform:rotate(45deg);
	transform:rotate(45deg)
}
.cd-search-trigger span {
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left:0
}
.cd-search-trigger span::before, .cd-search-trigger span::after {
	content:'';
	position:absolute;
	display:inline-block;
	height:3px;
	width:22px;
	top:50%;
	margin-top:-2px;
	left:50%;
	margin-left:-11px;
	background:#fff;
	opacity:0;
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
	-webkit-transition:opacity .3s, -webkit-transform .3s;
	-moz-transition:opacity .3s, -moz-transform .3s;
	transition:opacity .3s, transform .3s
}
.cd-search-trigger span::before {
	-webkit-transform:rotate(45deg);
	-moz-transform:rotate(45deg);
	-ms-transform:rotate(45deg);
	-o-transform:rotate(45deg);
	transform:rotate(45deg)
}
.cd-search-trigger span::after {
	-webkit-transform:rotate(-45deg);
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-o-transform:rotate(-45deg);
	transform:rotate(-45deg)
}
.cd-search-trigger.search-is-visible::before, .cd-search-trigger.search-is-visible::after {
	opacity:0
}
.cd-search-trigger.search-is-visible span::before, .cd-search-trigger.search-is-visible span::after {
	opacity:1
}
.cd-search-trigger.search-is-visible span::before {
	-webkit-transform:rotate(135deg);
	-moz-transform:rotate(135deg);
	-ms-transform:rotate(135deg);
	-o-transform:rotate(135deg);
	transform:rotate(135deg)
}
.cd-search-trigger.search-is-visible span::after {
	-webkit-transform:rotate(45deg);
	-moz-transform:rotate(45deg);
	-ms-transform:rotate(45deg);
	-o-transform:rotate(45deg);
	transform:rotate(45deg)
}
.cd-nav-trigger span, .cd-nav-trigger span::before, .cd-nav-trigger span::after {
	position:absolute;
	display:inline-block;
	height:3px;
	width:24px;
	background:#fff
}
.cd-nav-trigger span {
	position:absolute;
	top:50%;
	right:10px;
	margin-top:-2px;
	-webkit-transition:background .3s .3s;
	-moz-transition:background .3s .3s;
	transition:background .3s .3s
}
.cd-nav-trigger span::before, .cd-nav-trigger span::after {
	content:'';
	right:0;
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
	-webkit-transform-origin:0 50%;
	-moz-transform-origin:0 50%;
	-ms-transform-origin:0 50%;
	-o-transform-origin:0 50%;
	transform-origin:0 50%;
	-webkit-transition:-webkit-transform .3s .3s;
	-moz-transition:-moz-transform .3s .3s;
	transition:transform .3s .3s
}
.cd-nav-trigger span::before {
	top:-6px
}
.cd-nav-trigger span::after {
	top:6px
}
.cd-nav-trigger.nav-is-visible span {
	background:rgba(46,50,51,0)
}
.cd-nav-trigger.nav-is-visible span::before, .cd-nav-trigger.nav-is-visible span::after {
	background:#fff
}
.cd-nav-trigger.nav-is-visible span::before {
	-webkit-transform:translateX(4px) translateY(-3px) rotate(45deg);
	-moz-transform:translateX(4px) translateY(-3px) rotate(45deg);
	-ms-transform:translateX(4px) translateY(-3px) rotate(45deg);
	-o-transform:translateX(4px) translateY(-3px) rotate(45deg);
	transform:translateX(4px) translateY(-3px) rotate(45deg)
}
.cd-nav-trigger.nav-is-visible span::after {
	-webkit-transform:translateX(4px) translateY(2px) rotate(-45deg);
	-moz-transform:translateX(4px) translateY(2px) rotate(-45deg);
	-ms-transform:translateX(4px) translateY(2px) rotate(-45deg);
	-o-transform:translateX(4px) translateY(2px) rotate(-45deg);
	transform:translateX(4px) translateY(2px) rotate(-45deg)
}
@media only screen and (min-width:1170px) {
.cd-nav-trigger {
	display:none
}
}
.cd-primary-nav, .cd-primary-nav ul {
	position:fixed;
	top:0;
	right:0;
	height:100%;
	width:260px;
	background:#6c6cc7;
	overflow:auto;
	-webkit-overflow-scrolling:touch;
	z-index:105;
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0);
	-webkit-transition:-webkit-transform .3s;
	-moz-transition:-moz-transform .3s;
	transition:transform .3s
}
.cd-primary-nav a, .cd-primary-nav ul a {
	display:block;
	min-height:36px;
	line-height:50px;
	padding:0 20px;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	overflow:hidden;
	text-overflow:ellipsis;
	border-bottom:1px solid #3a3f40;
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0);
	will-change:transform, opacity;
	-webkit-transition:-webkit-transform .3s, opacity .3s;
	-moz-transition:-moz-transform .3s, opacity .3s;
	transition:transform .3s, opacity .3s
}
.cd-primary-nav.is-hidden, .cd-primary-nav ul.is-hidden {
	-webkit-transform:translateX(100%);
	-moz-transform:translateX(100%);
	-ms-transform:translateX(100%);
	-o-transform:translateX(100%);
	transform:translateX(100%)
}
.cd-primary-nav.moves-out>li>a, .cd-primary-nav ul.moves-out>li>a {
	-webkit-transform:translateX(-100%);
	-moz-transform:translateX(-100%);
	-ms-transform:translateX(-100%);
	-o-transform:translateX(-100%);
	transform:translateX(-100%);
	opacity:0
}
@media only screen and (max-width:1169px) {
.nav-on-left .cd-primary-nav, .nav-on-left .cd-primary-nav ul {
	right:auto;
	left:0
}
}
.cd-primary-nav .see-all a {
	color:#fff
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item, .cd-primary-nav .cd-nav-icons .cd-nav-item {
	height:80px;
	line-height:80px
}
.cd-primary.cd-primary-nav .cd-nav-gallery .cd-nav-item h-nav .cd-nav-gallery .cd-nav-item h3, .cd-primary-nav .cd-nav-icons .cd-nav-item h3 {
	overflow:hidden;
	text-overflow:ellipsis
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item {
	padding-left:90px
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item img {
	position:absolute;
	display:block;
	height:40px;
	width:auto;
	left:20px;
	top:50%;
	margin-top:-20px
}
.cd-primary-nav .cd-nav-icons .cd-nav-item {
	padding-left:75px
}
.cd-primary-nav .cd-nav-icons .cd-nav-item p {
	color:#fff;
	font-size:1.3rem;
	display:none
}
.cd-primary-nav .cd-nav-icons .cd-nav-item::before {
	content:'';
	display:block;
	position:absolute;
	left:20px;
	top:50%;
	margin-top:-20px;
	width:40px;
	height:40px;
	background-repeat:no-repeat;
	background-position:center center;
	background-size:40px 40px
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-1::before {
	background-image:url("../img/line-icon-1.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-2::before {
	background-image:url("../img/line-icon-2.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-3::before {
	background-image:url("../img/line-icon-3.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-4::before {
	background-image:url("../img/line-icon-4.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-5::before {
	background-image:url("../img/line-icon-5.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-6::before {
	background-image:url("../img/line-icon-6.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-7::before {
	background-image:url("../img/line-icon-7.svg")
}
.cd-primary-nav .cd-nav-icons .cd-nav-item.item-8::before {
	background-image:url("../img/line-icon-8.svg")
}
@media only screen and (max-width:1169px) {
.cd-primary-nav {
	visibility:hidden;
	-webkit-transition:visibility 0s .3s;
	-moz-transition:visibility 0s .3s;
	transition:visibility 0s .3s
}
.cd-primary-nav.nav-is-visible {
	visibility:visible;
	-webkit-transition:visibility 0s 0s;
	-moz-transition:visibility 0s 0s;
	transition:visibility 0s 0s
}
}
@media only screen and (min-width:1200px) and (max-width:2700px) {
.cd-primary-nav>li>a {
	position:relative;
	display:inline-block;
	height:44px;
	line-height:44px;
	padding:0 14px;
	color:#fff;
	overflow:visible;
	border-bottom:none;
	-webkit-transition:color .3s, box-shadow .3s;
	-moz-transition:color .3s, box-shadow .3s;
	transition:color .3s, box-shadow .3s;
	border-right:1px solid #6c6cc7;
	text-align:center
}
}
@media only screen and (min-width:1170px) and (max-width:1200px) {
.cd-primary-nav>li>a {
	position:relative;
	display:inline-block;
	height:44px;
	line-height:44px;
	padding:0 8px;
	color:#fff;
	overflow:visible;
	border-bottom:none;
	-webkit-transition:color .3s, box-shadow .3s;
	-moz-transition:color .3s, box-shadow .3s;
	transition:color .3s, box-shadow .3s;
	border-right:1px solid #6c6cc7;
	text-align:center
}
}
@media only screen and (min-width:1170px) {
.cd-primary-nav {
	position:static;
	padding:0 0 0 0;
	height:auto;
	width:100%;
	float:left;
	overflow:visible;
	background:transparent
}
.cd-primary-nav:after {
	content:"";
	display:table;
	clear:both
}
.cd-primary-nav.moves-out>li>a {
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0);
	opacity:1
}
.cd-primary-nav ul {
	position:static;
	height:auto;
	width:auto;
	background:transparent;
	overflow:visible;
	z-index:3
}
.cd-primary-nav ul.is-hidden {
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0)
}
.cd-primary-nav ul.moves-out>li>a {
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0);
	opacity:1
}
.cd-primary-nav>li {
	float:left;
	margin-left:0
}
.cd-primary-nav>li>a {
	background:#6c6cc7;
	background:-moz-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-webkit-gradient(left top, left bottom, color-stop(0, #6c6cc7), color-stop(100%, #006));
	background:-webkit-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-o-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:-ms-linear-gradient(top, #6c6cc7 0, #006 100%);
	background:linear-gradient(to bottom, #6c6cc7 0, #006 100%);
	text-shadow:1px 1px 1px rgba(0,0,0,0.8);
	letter-spacing:.01em
}
.cd-primary-nav>li>a:hover {
	background-color:#cecece
}
.cd-primary-nav>li>a:hover {
	background:#00006b
}
.cd-primary-nav>li>a.first {
	border-left:0 solid #fff;
	padding-left:20px
}
.cd-primary-nav>li>a.last {
	border-right:0 solid #fff
}
.cd-primary-nav>li>a.section2 {
	line-height:16px;
	padding-top:4px
}
.cd-primary-nav>li>a:hover {
	color:#dedede
}
.cd-primary-nav>li>a.selected {
	color:#dedede
}
.cd-primary-nav .go-back, .cd-primary-nav .see-all {
	display:none
}
.cd-primary-nav .cd-secondary-nav, .cd-primary-nav .cd-nav-gallery, .cd-primary-nav .cd-nav-icons {
	position:absolute;
	top:46px;
	width:100%;
	background:#6c6cc7;
	padding:24px 34px 100px;
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0);
	-webkit-transition:opacity .3s 0s, visibility 0s 0s;
	-moz-transition:opacity .3s 0s, visibility 0s 0s;
	transition:opacity .3s 0s, visibility 0s 0s
}
.cd-primary-nav .cd-secondary-nav:after, .cd-primary-nav .cd-nav-gallery:after, .cd-primary-nav .cd-nav-icons:after {
	content:"";
	display:table;
	clear:both
}
.cd-primary-nav .cd-secondary-nav.is-hidden, .cd-primary-nav .cd-nav-gallery.is-hidden, .cd-primary-nav .cd-nav-icons.is-hidden {
	opacity:0;
	visibility:hidden;
	-webkit-transition:opacity .3s 0s, visibility 0s .3s;
	-moz-transition:opacity .3s 0s, visibility 0s .3s;
	transition:opacity .3s 0s, visibility 0s .3s
}
.cd-primary-nav .cd-secondary-nav>.see-all, .cd-primary-nav .cd-nav-gallery>.see-all, .cd-primary-nav .cd-nav-icons>.see-all {
	display:block;
	position:absolute;
	left:0;
	bottom:0;
	height:80px;
	width:100%;
	overflow:hidden;
	margin:0;
	padding:0;
	background-color:#393994
}
.cd-primary-nav .cd-secondary-nav>.see-all a, .cd-primary-nav .cd-nav-gallery>.see-all a, .cd-primary-nav .cd-nav-icons>.see-all a {
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	font-size:2.2rem;
	font-weight:bold;
	text-align:center;
	line-height:80px;
	border-top:1px solid #e2e3df;
	border-bottom:none;
	margin:0;
	padding:0;
	-webkit-transition:color .2s, background .2s, border .2s;
	-moz-transition:color .2s, background .2s, border .2s;
	transition:color .2s, background .2s, border .2s
}
.cd-primary-nav .cd-secondary-nav>.see-all a:hover, .cd-primary-nav .cd-nav-gallery>.see-all a:hover, .cd-primary-nav .cd-nav-icons>.see-all a:hover {
	background:#fff;
	border-color:#fff;
	color:#6c6cc7
}
.cd-primary-nav .cd-secondary-nav>li {
	height:auto;
	width:23%;
	float:left;
	margin-right:2.66%;
	border-right:1px solid #7f7fce;
	overflow:hidden;
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling:touch
}
.cd-primary-nav .cd-secondary-nav>li:nth-child(4n+2) {
	margin-right:0;
	border-right:none
}
.cd-primary-nav .cd-secondary-nav>li>a {
	color:#00006b;
	font-family:'open_sanssemibold';
	font-size:11pt;
	margin:0
}
.cd-primary-nav .cd-secondary-nav a {
	min-height:30px;
	line-height:30px;
	padding:4px 0;
	color:#fff;
	border-bottom:none;
	font-size:9pt;
	min-height:0;
	overflow:visible;
	line-height:11pt
}
.cd-primary-nav .cd-secondary-nav a:hover {
	color:#21273f
}
.cd-primary-nav .cd-secondary-nav ul {
	-webkit-transform:translateZ(0);
	-moz-transform:translateZ(0);
	-ms-transform:translateZ(0);
	-o-transform:translateZ(0);
	transform:translateZ(0)
}
.cd-primary-nav .cd-secondary-nav ul ul {
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%
}
.cd-primary-nav .cd-secondary-nav ul ul.is-hidden {
	-webkit-transform:translateX(100%);
	-moz-transform:translateX(100%);
	-ms-transform:translateX(100%);
	-o-transform:translateX(100%);
	transform:translateX(100%)
}
.cd-primary-nav .cd-secondary-nav ul ul .go-back {
	display:block
}
.cd-primary-nav .cd-secondary-nav ul ul .go-back a {
	color:transparent
}
.cd-primary-nav .cd-secondary-nav ul ul .see-all {
	display:block
}
.cd-primary-nav .cd-secondary-nav .moves-out>li>a {
	-webkit-transform:translateX(-100%);
	-moz-transform:translateX(-100%);
	-ms-transform:translateX(-100%);
	-o-transform:translateX(-100%);
	transform:translateX(-100%)
}
.cd-primary-nav .cd-nav-gallery li {
	width:22%;
	float:left;
	margin:0 4% 40px 0
}
.cd-primary-nav .cd-nav-gallery li:nth-child(4n+2) {
	margin-right:0
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item {
	border-bottom:none;
	padding:0;
	height:auto;
	line-height:1.2
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item img {
	position:static;
	margin-top:0;
	height:auto;
	width:100%;
	margin-bottom:.6em
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item h3 {
	color:#b6b6e3;
	padding:0 .4em;
	font-size:11pt;
	font-family:'open_sanssemibold'
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item p {
	color:#dbdbf1;
	padding:0 .4em;
	font-size:10pt
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item h3 a:hover, .cd-primary-nav .cd-nav-gallery .cd-nav-item p a:hover {
	color:#fff;
	text-decoration:none
}
.cd-primary-nav .cd-nav-icons li {
	width:32%;
	float:left;
	margin:0 2% 20px 0
}
.cd-primary-nav .cd-nav-icons li:nth-child(3n+2) {
	margin-right:0
}
.cd-primary-nav .cd-nav-icons .cd-nav-item {
	border-bottom:none;
	height:80px;
	line-height:1.2;
	padding:24px 0 0 0
}
.cd-primary-nav .cd-nav-icons .cd-nav-item:hover {
	background:#f6f6f5
}
.cd-primary-nav .cd-nav-icons .cd-nav-item h3 {
	color:#21273f;
	font-weight:bold
}
.cd-primary-nav .cd-nav-icons .cd-nav-item p {
	display:block
}
.cd-primary-nav .cd-nav-icons .cd-nav-item::before {
	left:25px
}
}
.has-children>a, .go-back a {
	position:relative
}
@media only screen and (min-width:1170px) {
.has-children>a::before, .has-children>a::after, .go-back a::before, .go-back a::after {
	background:#c9cbc4
}
.has-children>a:hover::before, .has-children>a:hover::after, .go-back a:hover::before, .go-back a:hover::after {
	background:#21273f
}
}
.has-children>a {
	padding-right:40px
}
.cd-primary-nav .go-back a {
	padding-left:0
}
@media only screen and (min-width:1170px) {
.cd-primary-nav .go-back a {
	padding-left:20px
}
.cd-primary-nav .go-back a::before, .cd-primary-nav .go-back a::after {
	left:1px
}
}
.cd-search {
	position:absolute;
	height:120px;
	top:186px;
	width:100%;
	left:0;
	z-index:3;
	opacity:0;
	visibility:hidden;
	-webkit-transition:opacity .3s 0s, visibility 0s .3s;
	-moz-transition:opacity .3s 0s, visibility 0s .3s;
	transition:opacity .3s 0s, visibility 0s .3s
}
.cd-search form {
	height:100%;
	width:100%
}
.cd-search input {
	border-radius:0;
	border:none;
	background:#fff;
	height:100%;
	width:100%;
	padding:0 5%;
	box-shadow:inset 0 1px 0 #e2e3df, 0 3px 6px rgba(0,0,0,0.05);
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
	-o-appearance:none;
	appearance:none
}
.cd-search input::-webkit-input-placeholder {
color:#c9cbc4
}
.cd-search input::-moz-placeholder {
color:#c9cbc4
}
.cd-search input:-moz-placeholder {
color:#c9cbc4
}
.cd-search input:-ms-input-placeholder {
color:#c9cbc4
}
.cd-search input:focus {
	outline:none
}
.cd-search.is-visible {
	opacity:1;
	visibility:visible;
	-webkit-transition:opacity .3s 0s, visibility 0s 0s;
	-moz-transition:opacity .3s 0s, visibility 0s 0s;
	transition:opacity .3s 0s, visibility 0s 0s
}
.nav-is-fixed .cd-search {
	position:fixed
}
#cd-primary-ph, #cd-primary-ls {
	display:none
}
@media only screen and (max-width:1170px) {
#cd-primary-ph, #cd-primary-ls {
	display:inline
}
.cd-primary-nav>li>a {
	padding:5px 7px;
	font-size:11pt;
    color: white;
}
.cd-primary-nav>li#cd-primary-ph>a {
	float:left;
	width:33%;
	text-align:center
}
}
@media only screen and (min-width:1170px) {
.cd-search input {
	padding:0 2em;
	font-size:3.2rem;
	font-weight:300
}
}
.cd-overlay {
	position:fixed;
	z-index:2;
	height:100%;
	width:100%;
	top:0;
	left:0;
	cursor:pointer;
	background-color:rgba(1,15,54,0.6);
	visibility:hidden;
	opacity:0;
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;
	-webkit-transition:opacity .3s 0s, visibility 0s .3s, -webkit-transform .3s 0s;
	-moz-transition:opacity .3s 0s, visibility 0s .3s, -moz-transform .3s 0s;
	transition:opacity .3s 0s, visibility 0s .3s, transform .3s 0s
}
.cd-overlay.is-visible {
	opacity:1;
	visibility:visible;
	-webkit-transition:opacity .3s 0s, visibility 0s 0s, -webkit-transform .3s 0s;
	-moz-transition:opacity .3s 0s, visibility 0s 0s, -moz-transform .3s 0s;
	transition:opacity .3s 0s, visibility 0s 0s, transform .3s 0s
}
@media only screen and (max-width:1169px) {
.cd-overlay.is-visible {
	-webkit-transform:translateX(-260px);
	-moz-transform:translateX(-260px);
	-ms-transform:translateX(-260px);
	-o-transform:translateX(-260px);
	transform:translateX(-260px)
}
.nav-on-left .cd-overlay.is-visible {
	-webkit-transform:translateX(260px);
	-moz-transform:translateX(260px);
	-ms-transform:translateX(260px);
	-o-transform:translateX(260px);
	transform:translateX(260px)
}
.cd-overlay.is-visible.search-is-visible, .nav-on-left .cd-overlay.is-visible.search-is-visible {
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	-o-transform:translateX(0);
	transform:translateX(0)
}
}
.no-js .cd-primary-nav {
	position:relative;
	height:auto;
	width:100%;
	overflow:visible;
	visibility:visible;
	z-index:2
}
.no-js .cd-search {
	position:relative;
	top:0;
	opacity:1;
	visibility:visible
}
@media only screen and (min-width:1170px) {
.no-js .cd-primary-nav {
	position:absolute;
	z-index:3;
	display:inline-block;
	width:auto;
	top:0;
	right:150px;
	padding:0
}
.no-js .nav-is-fixed .cd-primary-nav {
	position:fixed
}
}
#subNav .nav>li>a {
	position:relative;
	display:block;
	padding:10px 4px 10px 0;
	color:#fff;
	font-family:'open_sanssemibold';
	text-shadow:1px 1px 1px #000
}
#subNav .nav>li em {
	font-style:normal
}
#subNav .nav>li>a:hover, #subNav .nav>li>a:focus {
	color:#6c6cc7;
	background-color:transparent
}
#subNav .nav>li>a:after {
	content:" ";
	color:#fff;
	margin-left:4px
}
#subNav .nav>li>a.last:after {
	content:"";
	color:#fff;
	margin-left:0
}
.nav>li>a:hover, .nav>li>a:focus {
	text-decoration:none;
	background-color:#00006b
}
hr {
	margin-top:20px;
	margin-bottom:20px;
	border-width:1px 0 0;
	border-style:solid none none;
	border-color:#aeaeae -moz-use-text-color -moz-use-text-color;
	-moz-border-top-colors:none;
	-moz-border-right-colors:none;
	-moz-border-bottom-colors:none;
	-moz-border-left-colors:none;
	border-image:none
}
.pagination {
	margin:0 0;
	background-color:#fdeffa;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
	
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}



.breadcrumb>li+li:before {
	padding:0 5px;
	color:#00006b;
	content:"/\00a0"
}
.popover-content {
	width:100%
}
.breadcrumb {
	padding:8px 15px;
	list-style:outside none none;
	background-color:#d1d1ff;
	border-radius:0;
	margin-bottom:0;
	font-size:9pt;
	margin:0 0 2px
}
#sideNav {
	min-height:100px
}
@media only screen and (max-width: 1090px) {
	#sideNav  {
		display: none;
	}
}
.sidebar {
	z-index:1000;
	display:block;
	padding:0 20px;
	overflow-x:hidden;
	overflow-y:auto;
	background-color:#00006b;
	font-size:9pt
}
.nav-sidebar {
	margin-right:-21px;
	margin-bottom:0;
	margin-left:-20px
}
.nav-sidebar>li>a {
	padding-right:20px;
	padding-left:20px;
	background-color:#380e7d;
	color:#fff;
	font-family:'open_sanssemibold'
}
.nav-group .nav-sidebar>.active>a {
	color:#fff;
	background-color:#00006b
}
.nav-group>.active>a {
	background-color:#be1698
}
.nav-group>.active>a:hover {
	background-color:#be1698
}
.nav-sidebar2 {
	margin-right:0;
	margin-bottom:0;
	margin-left:0
}
.nav-sidebar2>li>a {
	padding:5px 20px;
	color:#444;
	background-color:#d1d1ff
}
.nav-sidebar2>li>a:hover {
	color:#fff;
	background-color:#9e9eff
}
.nav-sidebar2>li>a.active {
	color:#000;
	font-family:'open_sanssemibold';
	background-color:#f8c2ec
}
.nav-group>.nav-sidebar2>li>a.active {
	color:#000;
	font-family:'open_sanssemibold';
	background-color:#fff
}
.nav-sidebar3 {
	margin-right:0;
	margin-bottom:0;
	margin-left:0
}
.nav-sidebar3>li>a {
	padding:5px 20px;
	color:#444;
	background-color:#f8c2ec;
	font-family:'open_sanssemibold'
}
.nav-sidebar3>li>a:hover {
	color:#444;
	background-color:#f5abe4
}
.nav-sidebar3>li>a.active {
	color:#fff;
	background-color:#f07dd6;
	font-family:'open_sanssemibold'
}
.nav-sidebar3>li>a.active2 {
	color:#be1698;
	background-color:#fff;
	font-family:'open_sanssemibold'
}
.nav-sidebar3>li>a.active2:hover {
	color:#be1698;
	background-color:#f8c2ec
}
.nav-sidebar3>li>a.active3 {
	color:#00006b;
	background-color:#fdeffa;
	font-family:'open_sanssemibold'
}
.nav-sidebar3>li>a.active3:hover {
	color:#00006b;
	background-color:#fbd8f3
}
#mainContentHome h2 {
	display:block;
	width:100%;
	padding:3px 6px;
	font-size:14pt;
	background-color:#6c6cc7;
	color:#fff;
	margin:15px 0 5px
}
#mainContentHome p {
	font-size:9pt
}
#homeSale div.saleImg {
	text-align:center;
	margin:0 auto
}
#homeSale div.saleText {
	text-align:center
}
#homeSale .saleText h3 {
	font-size:12pt;
	margin:2pt 0 4pt
}
#homeSale .saleText h4 {
	font-size:11pt;
	margin:2pt 0 4pt
}
#homeSale .saleText p a.btn {
	position:realtive;
	bottom:0
}
#BannerWrapper {
	min-height:240px
}
.bannerWrapper, .banneerInfo {
	background-color:#6c6cc7;
	color:#fff
}
.banneerInfo {
	padding-left:45px
}
#bx-pager a img {
	border:solid #fff 2px
}
#bx-pager a.active img {
	border:solid #6c6cc7 2px
}
#saleItems h4 {
	color:red
}
#saleItems small em {
	font-style:normal;
	text-decoration:line-through
}
::-webkit-input-placeholder {
color:#aeaeae
}
:-moz-placeholder {
color:#aeaeae
}
::-moz-placeholder {
color:#aeaeae
}
:-ms-input-placeholder {
color:#aeaeae
}
::-webkit-input-placeholder {
color:#aeaeae
}
:-moz-placeholder {
color:#aeaeae
}
::-moz-placeholder {
color:#aeaeae
}
:-ms-input-placeholder {
color:#aeaeae
}
nav ol, ul {
	list-style:outside none none;
	font-size:9pt
}
a.cd-nav-trigger:hover, .cd-nav-trigger:focus, .cd-search-trigger:hover, .cd-search-trigger:focus {
	color:transparent
}
.cd-primary-nav ul.is-hidden {
	margin-left:-40px
}
.cd-primary-nav ul.is-hidden {
	margin-left:-38px;
	min-height:auto;
	border-bottom:0 solid #dedede;
	margin-bottom:5pt
}
#cart {
	margin:0 0 10px
}
#cart span.icon, #srchMob span.icon {
	float:left;
	text-align:center;
	width:38px;
	height:38px;
	line-height:38px;
	background-color:#6c6cc7;
	display:block;
	color:#fff;
	font-size:16px;
	margin-right:4px
}
#cart .icon2 {
	float:right;
	text-align:center;
	width:auto;
	height:38px;
	line-height:38px;
	background-color:#3abec0;
	display:block;
	color:#fff;
	font-size:16px;
	margin-right:0;
	padding:0 10px;
	font-size:10pt
}
.well {
	min-height:20px;
	padding:10px;
	margin-bottom:0;
	background-color:#f5f5f5;
	border:1px solid #e3e3e3;
	border-radius:0;
	box-shadow:none
}
a {
	color:#00006b
}
#pageContent h1 {
	margin-top:0px;
	margin-bottom:10pt;
	color:#00006b;
	font-size:20pt;
	line-height:26pt
}
#pageContent h2 {
	margin-top:10px;
	margin-bottom:10pt;
	color:#00006b;
	font-size:16pt;
	line-height:20pt
}
#pageContent h3 {
	color:#00006b;
	font-size:11pt;
	line-height:15pt;
	margin-top:0
}
#pageContent h5 {
	color:#00006b;
	font-size:11pt;
	line-height:14pt
}
#productSection {
	padding-top:20px
}
#productSection h2 {
	color:#00006b;
	font-size:13px;
	font-family:"open_sanssemibold";
	margin:4pt 0;
	line-height:15px;
}
#productSection h4 {
	color: #00006b;
    font-size: 14px;
    margin: 0;
    line-height: 15px;
}
#productSection p {
    font-size: 12px;
    margin: 0 0 8px;
    line-height: 13px;
}
#productSection td span {
    background-color: #f4f4f4;
    display: block;
    padding: 8px 0 9px 3px;
    border-right: 2px solid #ffffff;
    /* line-height: 20px; */
    font-size: 12px;
}
#productSection em {
	font-style: normal;
    display: block;
    height: 44px;
    padding: 0 0 0 4px;
    line-height: 42px;
    font-size: 12px;
}
#productSection select {
	width:100%;
	min-height:22px;
    padding: 4px;
    margin: 4px 0;
	font-size:8pt;
	line-height:1.42857143;
	color:#555;
	background-color:#fff;
	background-image:none;
	border:1px solid #ccc;
	border-radius:0
}
.productSectionTop {
	width: 100%;
    margin-bottom: 1px;
    min-height: 98px;
    float: left;
    }
    
.productDetails {
   min-height:112px;
}

@media only screen and (max-width: 767px) {
  .productSectionTop {
  	float: none;
    }	
 }
/* Qty Select */
#productSection select.selQty {
	float:left;
	width: 30%;
	height:32px;
	}
/* spacer for items without colour select */
.colorSelectSpacer {
	width: 100%;
	height: 30px;
}



#pageContent h5.pagePagination a {
	display:block;
	padding:2px 4px;
	border:1px solid #00006b;
	float:left
}
#productSection h2#specialHeading {
	font-size:16pt;
	line-height:25pt;
	font-weight:normal;
	font-family:"open_sansregular";
	color:#be1698
}
#productSection h2#specialHeading a {
	color:#00006b
}
#productSection hr, hr {
	margin:15px 0;
	border-color:#f07dd6
}
#productCategory h2 {
	font-size:9pt;
	line-height:10.5pt;
	margin:6pt 0 2pt 0;
	font-family:'open_sanssemibold';
	color:#00006b
}
#productCategory p {
	font-size:8.5pt;
	margin:0 0 12pt;
	line-height:10pt
}
#productSupplement h2 {
	font-size:14pt;
	margin:1pt 0 8pt;
	color:#00006b
}
#productSupplement h6 {
	display:block;
	text-align:center;
	margin:1pt 0 4pt
}
.productWrapper {
	min-height:250px;
    margin-bottom: 0px;
    position: relative;
}
.productButtons {
    padding: 0 0 15px 0;
    margin: 0;
    min-height: 195px;
}
.moreInfo {
    margin-top: 15px;
    background-color: #f8c2ec;
    position: absolute;
    bottom: 30px;
    width: 100%;
    padding: 4px 3px 3px;
}

@media only screen and (max-width: 767px ) {
	.moreInfo {
		    position: static;
	}
	.productWrapper {
		text-align: center;
	}
	.productSectionTop img {
		margin: 0 auto;
	}
	.productDetails {
		min-height: 10px;
	}
	#pageContent h1 {
	    text-align: center;
	}
}

#productPackagingIcons {
	margin:4px 0
}
#productDetailCore {
	margin-bottom:20px
}
#pageContent #productDetailCore h2 {
	margin-top:0;
	margin-bottom:5pt;
	font-size:16pt;
	line-height:20pt
}
#pageContent #productDetailCore p {
	margin:0 0 4pt;
	line-height:13pt
}
#pageContent #productDetailCore .table {
	margin-bottom:0
}
#productDetailCore select {
	height:38px;
	margin:10px 0;
}
#pageContent #productDetailCore td p, #pageContent #productDetailCore td span {
    margin: 0 0 4pt;
    line-height: 43pt;
}
/**/
.imageThumb {
	position:relative;
	border:1px solid #dedede;
}
.pngFlag {
	position:absolute; 
	top:0; 
	right:0;
}
#bcTrail {
		margin: 2px 0 10px;
	}
.catHr {
	border-color: #f07dd6;
	border-width: 2px;
	margin: 5px 0 5px;
}
/**/
#gallerySections h5 {
	text-align:center;
	font-size:10pt;
	line-height:11.5pt
}
#gallerySections img {
	width:100%
}
#swarovski {
	text-align:center
}
#swarovski h5 {
	font-size:10pt;
	line-height:11pt;
	font-family:'open_sanssemibold';
	margin:4pt 0 2pt
}
#swarovski p {
	font-size:8.5pt;
	line-height:10pt;
	margin:0
}
#productSwarovski {
	text-align:center
}
#productSwarovski h4 {
	font-size:10pt
}
#swProductDetailInfo label {
	display:inline-block;
	min-width:150px;
	min-height:20px;
	margin:10px 0 0;
	font-weight:normal;
	font-family:'open_sanssemibold'
}
#swProductDetailInfo input.fieldStet {
	background-color:#fff;
	border:0;
	font-family:'open_sanssemibold';
	color:#00006b
}
#swProductDiscounts li {
	font-size:10pt;
	line-height:12pt;
	font-family:'open_sanssemibold';
	color:#00006b
}
.specialPod {
	min-height:225px;
}
#specialContent h2 {
	margin-top:10px;
	margin-bottom:10pt;
	color:#00006b;
	font-size:16pt;
	line-height:20pt;
	display:block;
	border-top:1px solid #dedede;
	padding-top:4px
}
label.error {
	color:red;
	font-family:'open_sanssemibold';
	font-weight:normal
}
label {
	display:inline-block;
	max-width:100%;
	margin-bottom:5px;
	font-weight:normal;
	font-family:'open_sanssemibold'
}
.modal-content {
	background-color:#dedede;
	border:2px solid #fff
}
.form-control, .pagination>li>a, .pagination>li>span {
	background-color:#fdeffa;	
    border-color: #be1698;
    color:#00006b;
    border-radius: 0;
}
.form-control {
	background-color:#fff;	
}
.form-control:hover, .pagination>li>a:hover, .pagination>li>span:hover {
	background-color: lighten(#fdeffa,20);
}
.pagination>li>span.current, .pagination>li>span.current a {
    font-weight: bold;
    color: #fff;
    background-color: #be1698;
}

div.info {
	background-color:#d9edf7;
	padding:8px 0;
	text-align:center
}
.shopSubLine {
	background-color:#6c6cc7;
	color:#fff
}
.shopBilling {
	background-color:#ededf8;
	color:#444;
	display:block;
	padding:5px;
	border-top:1px solid #dedede;
	border-bottom:1px solid #dedede;
	text-align:center
}
.shopBillingInfo {
	background-color:#f8f8fc
}
@media only screen and (max-device-width:1024px) {
.homePodPad {
	padding:0
}
#homeBanner p {
	margin:0 0 5px;
	font-size:8pt
}
#homeBanner p:last-child {
	display:none
}
#homeBanner .banneerInfo {
	padding-left:10px
}
#homeBanner .bx-controls {
	display:none
}
#homeBanner .bx-controls:hover {
	display:inline
}
#homeBanner .bx-wrapper {
	background-color:#6c6cc7;
	padding-bottom:0
}
#homeBanner .bx-wrapper img {
	max-width:99.9%
}
#homeSale div.saleImg, #homeSale div.saleText {
	background-color:#fff
}
#homeSale div.saleImg img {
	text-align:center;
	margin:0 auto
}
}
@media only screen and (min-device-width:768px) {
.cartTableLabelShort {
	display:none
}
.cartTableButtons {
	text-align:right
}
}
@media only screen and (max-device-width:767px) {
.cartTableLabel {
	display:none
}
.cartTableLabelShort {
	display:inline
}
.cartTableLabel {
	display:none
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	padding:8px 4px;
	vertical-align:middle
}
.cartTableButtons {
	text-align:center;
	padding:10px 0
}
table#cartTable {
	margin-bottom:0
}
#cartTable p, #cartTable td {
	font-size:9pt;
	line-height:9pt
}
#cartTable p {
	display:block;
	margin:5px 0 0
}
#cartTable select.form-control {
	max-width:58px;
	padding:0
}
}
@media only screen and (max-device-width:340px) {
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	padding:8px 3px;
	vertical-align:middle
}
img.cartTableRemove {
	width:24px;
	height:auto
}
}
@media only screen and (max-device-width:1025px) {
.cd-primary-nav, .cd-primary-nav ul {
	width:280px
}
.cd-primary-nav>li>a {
	padding:5px 7px;
	font-size:11pt
}
.cd-primary-nav a, .cd-primary-nav ul a {
	min-height:36px;
	line-height:14pt;
	padding:15px 0;
	font-size:11pt
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item img {
	display:none
}
.cd-primary-nav a, .cd-primary-nav ul a {
	font-size:11pt
}
nav h3 {
	font-size:10pt;
	margin:0
}
#mainNav p {
	margin:0 0 10px;
	display:none
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item {
	padding-left:0
}
}
@media only screen and (min-device-width:770px) and (max-device-width:1025px) {
#bx-pager, .breadcrumb {
	display:none
}
#subNav .nav>li>a {
	padding:8px 2px 8px 0
}
#subNav .nav>li>a::after {
	content:"";
	margin-left:2px;
	font-size:7pt
}
}
@media only screen and (min-device-width:480px) and (max-device-width:768px) {
#bx-pager, .breadcrumb {
	display:none
}
}
@media only screen and (max-device-width:700px) {
#sideNav {
	display:none
}
}
@media only screen and (max-device-width:500px) {
#selItems {
	display:none
}
}
@media only screen and (min-device-width:1025px) and (max-device-width:1169px) {
#BannerWrapper {
	min-height:240px
}
#BannerWrapper h2 {
	font-size:16pt
}
#BannerWrapper h2 p {
	margin:0 0 10px;
	font-size:9pt;
	line-height:13pt
}
#subNav .nav>li>a {
	position:relative;
	display:block;
	padding:8px 10px 8px 0
}
.cd-primary-nav a, .cd-primary-nav ul a {
	display:block;
	min-height:36px;
	line-height:14pt;
	padding:15px 0;
	overflow:hidden;
	text-overflow:ellipsis;
	border-bottom:1px solid #3a3f40;
	transform:translateZ(0);
	will-change:transform, opacity;
	transition:transform .3s ease 0s, opacity .3s ease 0s;
	font-size:12pt
}
.footer {
	height:265px
}
}
@media only screen and (min-device-width:1071px) and (max-device-width:1199px) {
#BannerWrapper {
	min-height:240px
}
#BannerWrapper h2 {
	font-size:16pt
}
#BannerWrapper h2 p {
	margin:0 0 10px;
	font-size:9pt;
	line-height:13pt
}
#subNav .nav>li>a {
	padding:8px 4px 8px 0
}
.footer {
	height:265px
}
}
@media only screen and (min-device-width:768px) and (max-device-width:800px) {
#srchBox input#search {
	border:0;
	height:38px;
	width:160px
}
#subNav .nav>li>a {
	padding:8px 2px 8px 0
}
#subNav .nav>li>a::after {
	content:"";
	margin-left:2px;
	font-size:7pt
}
}
@media only screen and (min-device-width:320px) and (max-device-width:480px) {
#sideNav, #bx-pager, .infoSmart, .breadcrumb {
	display:none
}
body {
	margin-bottom:480px
}
#BannerWrapper {
	min-height:240px
}
#BannerWrapper h2 {
	font-size:16pt
}
#BannerWrapper h2 p {
	margin:0 0 10px;
	font-size:9pt;
	line-height:13pt
}
#subNav .nav>li>a {
	position:relative;
	display:block;
	padding:8px 10px 8px 0
}
.cd-primary-nav a, .cd-primary-nav ul a {
	display:block;
	min-height:36px;
	line-height:14pt;
	padding:15px 0;
	overflow:hidden;
	text-overflow:ellipsis;
	border-bottom:1px solid #a79fb1;
	transform:translateZ(0);
	will-change:transform, opacity;
	transition:transform .3s ease 0s, opacity .3s ease 0s;
	font-size:10pt;
	line-height:12pt
}
.footer {
	height:480px
}
}
@media only screen and (max-width:320px) {
.cd-main-content {
	margin-top:8px
}
.cd-header-buttons {
	top:0
}
#productCategory {
	margin-top:120px
}
#productSection {
	padding-top:0
}
#pageContent {
	margin-top:116px
}
#pageContent h1 small {
	display:none
}
#homeBanner {
	margin-top:110px
}
#homeBanner .btn {
	text-align:center;
	margin:0 auto;
	float:none;
	display:block
}
.bx-wrapper {
	margin-top:120px
}
.bx-wrapper .bx-controls-direction a {
	top:70%
}
}
@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
#BannerWrapper {
	min-height:240px
}
#BannerWrapper h2 {
	font-size:16pt
}
#BannerWrapper h2 p {
	margin:0 0 10px;
	font-size:9pt;
	line-height:13pt
}
#subNav .nav>li>a {
	position:relative;
	display:block;
	padding:8px 10px 8px 0
}
.cd-primary-nav a, .cd-primary-nav ul a {
	display:block;
	min-height:36px;
	line-height:14pt;
	padding:15px 0;
	overflow:hidden;
	text-overflow:ellipsis;
	border-bottom:1px solid #3a3f40;
	transform:translateZ(0);
	will-change:transform, opacity;
	transition:transform .3s ease 0s, opacity .3s ease 0s;
	font-size:12pt
}
body {
	margin-bottom:400px
}
.footer {
	height:400px
}
#footerAwards, #footerAddress {
	text-align:center
}
}
@media only screen and (min-device-width:361px) and (max-device-width:767px) {
#sideNav, #bx-pager, .infoSmart, .breadcrumb {
	display:none
}
body {
	margin-bottom:480px
}
#BannerWrapper {
	min-height:240px
}
#BannerWrapper h2 {
	font-size:16pt
}
#BannerWrapper h2 p {
	margin:0 0 10px;
	font-size:9pt;
	line-height:13pt
}
#subNav .nav>li>a {
	position:relative;
	display:block;
	padding:8px 10px 8px 0
}
.cd-primary-nav a, .cd-primary-nav ul a {
	display:block;
	min-height:36px;
	line-height:14pt;
	padding:15px 0;
	overflow:hidden;
	text-overflow:ellipsis;
	border-bottom:1px solid #3a3f40;
	transform:translateZ(0);
	will-change:transform, opacity;
	transition:transform .3s ease 0s, opacity .3s ease 0s;
	font-size:12pt
}
.footer {
	height:560px
}
}
@media print {
header, .cd-main-header, #topRow, #mainNav, #sideNav, #footer, .productButtons, .btn {
	display:none
}
p {
	font-family:sans-serif;
	font-size:10pt;
	line-height:12pt;
	color:black
}
.cd-main-content {
	margin-top:10px
}
a[href]:after {
	content:none !important
}
}
